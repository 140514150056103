<template>
  <CContainer class="small-buttons-custom" fluid>
    <CRow class="mt-2" :gutters="false">
      <CCol col="12" xl="12">
        <CInput
          placeholder="Título"
          v-model='nota.title'
        />
        <CTextarea
          class="notas-tracking-textarea"
          placeholder="Descripción"
          v-model='nota.description'
        />
        <CAlert v-for="(toast,index) in toasts" :color="toast.color" closeButton :show.sync="currentAlertCounter">
          {{toast.message}}
        </CAlert>
      </CCol>
      <CCol col="4" xl="4">
      </CCol>
      <CCol col="4" xl="4">
        <CButton
          :disabled="savingNote"
          color="success"
          class="w-100 custom-button-info-content success d-flex justify-content-around align-items-center py-2"
          style="flex: 1;"
          @click="saveNote()"
        >
          <CSpinner color="white" size="sm" v-if="savingNote"/>
          <CIcon name="cilSave" size="xl" class="icons-svg" v-else/>
          Guardar Notas
        </CButton>
      </CCol>
      <CCol col="4" xl="4">
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import {createNote} from '../../../api/notes'

export default {
  name: 'NewNotesForm',
  props: ['userID', 'atencionID', 'trackingID', 'deviceID'],
  data(){
    return {
      toasts: [],
      nota: {
        title: '',
        description: '',
        type: 1
      },
      savingNote: false,
      currentAlertCounter: 4
    }
  },
  methods: {    
    saveNote(){
      const self = this
      this.currentAlertCounter = 4
      this.toasts = []
      if(!this.nota || (this.nota && (this.nota.title == '' || this.nota.description == '') ) ){
        this.toasts.push({
          title: 'Error',
          message: 'Por favor ingresa título y descripción.',
          color: 'danger'
        })
        return
      }
      this.savingNote = true
      const data = {
        user_id: this.userID,
        device_id: this.deviceID,
        atencion_id: this.atencionID,
        tracking_id: this.trackingID,
        title: this.nota.title,
        description: this.nota.description
      }
      createNote(data)
        .then(function(resp){
          self.savingNote = false
          self.nota = {
            title: '',
            description: '',
            type: 1
          }
          self.toasts.push({
            title: 'Nota guardada',
            message: 'La nota se ha guardado correctamente.',
            color: 'success'
          })
        })
        .catch(function(error){
          self.savingNote = false
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al guardar la información, por favor intenta nuevamente.',
            color: 'danger'
          })
        })
    },
  }
}
</script>
<style scoped>
  .alert{padding: 5px;font-size: 11px;margin-bottom: 0;}
  .alert >>> button.close{padding: 2px 5px;}
</style>