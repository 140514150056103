import router from './router'
// import { getToken } from '@/utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'

const whiteList = ['/login', '/auth-redirect', '/reestablecer'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {

	const token = localStorage.getItem('token')
	if(token){
    if(to.path === '/login'){
      next('/dashboard')
    }
  	next()
	}
	else{
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`)
    }
	}

})