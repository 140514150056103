<template>
	<div class="camera-controls">
		<div class="p-2 grid-controls">
			<div class="ptz-controls">
				<CButton
					size="sm"
					class="rounded-circle grid-button"
					variant="outline"
					@click="ptzMove('left')"
				>
					<font-awesome-icon
						icon="chevron-left"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
				<CButton
					size="sm"
					class="rounded-circle grid-button"
					variant="outline"
					@click="ptzMove('right')"
				>
					<font-awesome-icon
						icon="chevron-right"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
				<CButton
					size="sm"
					class="rounded-circle grid-button"
					variant="outline"
					@click="ptzMove('up')"
				>
					<font-awesome-icon
						icon="chevron-up"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
				<CButton
					size="sm"
					class="rounded-circle grid-button"
					variant="outline"
					@click="ptzMove('down')"
				>
					<font-awesome-icon
						icon="chevron-down"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
			</div>
			<div>
				<CButton
					class="rounded-circle grid-button"
					variant="outline"
					@click="refreshCamera()"
				>
					<font-awesome-icon
						icon="redo"
						style="color: white; font-size: 12px;"
					/>
				</CButton>
				<CButton
					class="rounded-circle grid-button"
					variant="outline"
					@click="setToMain()"
				>
					<font-awesome-icon
						icon="expand"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
			</div>
		</div>
	</div>
</template>

<script>
import { commandTuyaDevice } from '@/api/cameras'

export default {
	name: 'CameraButtonsGrid',
	props: ['cameraData', 'refreshCamera'],
	methods: {
		setToMain(){
			this.$store.commit('setMainCamera', this.cameraData.deviceId)
		},
		ptzMove(ptzDirection){
			const ptzValues = {
				'left': "6",
				'up': "0",
				'down': "4",
				'right': "2",
			}
			commandTuyaDevice({camera_id: this.cameraData.deviceId, command: 'ptz_control', value: ptzValues[ptzDirection]})
		},
	}
}
</script>

<style scoped>
	.camera-controls {
		position: relative;
	}
	.camera-controls:hover > .grid-controls{
		opacity: 1;
	}
	.grid-controls {
		opacity: 0;
		position: absolute;
		width: 100%;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.grid-button {
		background-color: rgba(0,0,0,0.4);
		aspect-ratio: 1;
		line-height: 1;
	}
	.ptz-controls{
		display: flex;
		gap: 4px;
	}
</style>