<template>
  <vue-draggable-resizable :resizable="false" :x="0" :y="200" class-name="calls-dragable" :z="9999999999" :parent="true" v-show="toCallData" style="position:absolute; z-index: 2000 ; background:#FFF; top:-50px;">
    <div class="d-flex align-items-center">
      <div class="d-flex justify-content-center align-items-center img-phone-calls mr-3 pulse animated infinite">
        <img class="white-color-image-overlay" height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
      </div>
      <div>
        <p class="mb-0 font-weight-bold">Llamada en curso</p>
        <p class="mb-0" style="font-size: 12px;" v-if="toCallData">Usuario: {{toCallData.name}}</p>
        <p class="mb-0" style="font-size: 12px;" v-if="toCallData">Teléfono: +{{toCallData.phoneToCall}}</p>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center mt-2" v-if="deviceStatus !== 0">
      <CButton
        color="primary"
        class="ml-2 my-1"
        @click="endCall()"
        size="sm"
      >
        <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
        Finalizar llamada en curso
      </CButton>
    </div>
    <div class="d-flex flex-column align-items-center mt-2" v-if="deviceStatus === 0">
      <p class="mb-0" style="font-size: 10px;">Llamada desconectada. ¿Deseas llamar nuevamente?.</p>
      <div class="d-flex align-items-center">
        <CButton
          color="success"
          class="ml-2 my-1"
          @click="call(toCallData.phoneToCall)"
          size="sm"
        >
          <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
          Reconectar llamada
        </CButton>
        <CButton
          color="primary"
          class="ml-2 my-1"
          @click="endCall()"
          size="sm"
        >
          <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
          Terminar
        </CButton>
      </div>
    </div>
    <p class="mb-0 text-center" style="font-size: 10px;">Puedes mover este cuadro donde quieras y seguir trabajando en la plataforma</p>
  </vue-draggable-resizable>
</template>

<script>
import {Device} from 'twilio-client'
import { mapGetters } from "vuex"
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import {newToken} from '../../api/calls'

export default {
  name: 'CallsHandler',
  components: {VueDraggableResizable},
  computed: {
    ...mapGetters(["toCallData"])
  },
  data(){
    return {
      deviceStatus: 9
    }
  },
  watch:{
    toCallData: function(data){
      if(data){
        this.call(data.phoneToCall)
      }
    }
  },
  methods: {    
    call(phoneToCall){
      const self = this
      Device.ready(function (device) {
        device.connect({"phoneNumber": '+' + phoneToCall})
      })
      Device.on('connect',function (device) {
        self.deviceStatus = 1
      })
      Device.on('disconnect',function (device) {
        self.deviceStatus = 0
        Device.destroy()
      })
      newToken()
        .then(function(resp){
          Device.setup(resp.token)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    endCall(){
      this.deviceStatus = 9
      this.$store.commit("setCallData", null)
      Device.destroy()
    }
  }
}
</script>
