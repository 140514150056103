<template>
  <div class="videocall-notes-container text-center">
    <CRow class="py-4 px-3" :gutters="false" v-show="expanded">
      <CCol col="12">
        <h6 class="mb-3">Agrega una nota a esta llamada</h6>
      </CCol>
      <CCol col="12">
        <CInput
          placeholder="Título"
          v-model='nota.title'
        />
        <CTextarea
          class="notas-tracking-textarea"
          placeholder="Descripción"
          v-model='nota.description'
        />
        <CAlert v-for="(toast,index) in toasts" :color="toast.color" closeButton :show.sync="currentAlertCounter">
          {{toast.message}}
        </CAlert>
      </CCol>
      <CCol col="12">
        <CButton
          :disabled="savingNote"
          color="success"
          size="sm"
          class="w-100 success d-flex justify-content-around align-items-center py-2"
          style="flex: 1;"
          @click="saveNote()"
        >
          <CSpinner color="white" size="sm" v-if="savingNote"/>
          Guardar Nota
        </CButton>
      </CCol>
      <CCol col="4" xl="4">
      </CCol>
    </CRow>
    <div class="d-flex align-items-center justify-content-between">
      <small class="px-2">Agregar Nota</small>
      <CButton @click="expanded = !expanded" color="light" size="sm">
        <font-awesome-icon :icon="expanded ? 'chevron-down':'chevron-up'"/>
      </CButton>
    </div>
  </div>
</template>

<script>
import {createNote} from '../../api/notes'

export default {
  name: 'NewNoteForm',
  props: ['userID', 'atencionID', 'trackingID', 'deviceID'],
  data(){
    return {
      expanded: false,
      toasts: [],
      nota: {
        title: '',
        description: '',
        type: 1
      },
      savingNote: false,
      currentAlertCounter: 4
    }
  },
  methods: {    
    saveNote(){
      const self = this
      this.currentAlertCounter = 4
      this.toasts = []
      if(!this.nota || (this.nota && (this.nota.title == '' || this.nota.description == '') ) ){
        this.toasts.push({
          title: 'Error',
          message: 'Por favor ingresa título y descripción.',
          color: 'danger'
        })
        return
      }
      this.savingNote = true
      const data = {
        user_id: this.userID,
        device_id: this.deviceID,
        atencion_id: this.atencionID,
        tracking_id: this.trackingID,
        title: this.nota.title,
        description: this.nota.description
      }
      createNote(data)
        .then(function(resp){
          self.savingNote = false
          self.nota = {
            title: '',
            description: '',
            type: 1
          }
          self.toasts.push({
            title: 'Nota guardada',
            message: 'La nota se ha guardado correctamente.',
            color: 'success'
          })
        })
        .catch(function(error){
          self.savingNote = false
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al guardar la información, por favor intenta nuevamente.',
            color: 'danger'
          })
        })
    },
  }
}
</script>
<style scoped>
  .alert{padding: 5px;font-size: 11px;margin-bottom: 0;}
  .alert >>> button.close{padding: 2px 5px;}
</style>