import {endpoints} from './endpoints'
import request from '../helpers/request'

export function createNote(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createNote,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}