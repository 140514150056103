<template>
  <CCarousel
    arrows
    animate
    class="custom-carousel videocall-pip"
  >
    <CCarouselItem class="h-100" v-for="index in Math.ceil((remotes.length + 1)/2)" :key="index">
    	<template slot="default">
    		<div class="d-flex justify-content-center align-items-center h-100">
	    		<div class="flex-grow-1 h-100" style="position: relative;" v-if="index === 1">
		    		<video autoplay :srcObject.prop="localStream" class="video-element"></video>
        		<div class="user-name-video" >Tú</b></div>
		    	</div>
	    		<div class="flex-grow-1 h-100" style="position: relative;" v-if="index === 1 && remotes.length > 0">
		    		<video autoplay :srcObject.prop="remotes[0].stream" class="video-element"></video>
        		<div class="user-name-video" >{{remotes[0].userName}}</b></div>
		    	</div>
	    		<div class="flex-grow-1 h-100" style="position: relative;" v-if="index > 1 && remotes.length > 0">
		    		<video autoplay :srcObject.prop="remotes[2*(index-1) - 1].stream" class="video-element"></video>
        		<div class="user-name-video" >{{remotes[2*(index-1) - 1].userName}}</b></div>
		    	</div>
		    </div>
    	</template>
  	</CCarouselItem>
  </CCarousel>
</template>

<script>

	export default {
	  name: 'VideoCallCarousel',
	  props: ['localStream', 'remotes'],
	  data(){
	    return {
	    	chunkRemotes: []
	    }
	  },
	  mounted(){
	  	console.log("remotes: ", Math.ceil((this.remotes.length + 1)/2))
	  	console.log(this.remotes.length)
	  },
	  methods: {
	  	chunkStreams(array, size) {
			  const chunked_arr = [];
			  let index = 0;
			  while (index < array.length) {
			    chunked_arr.push(array.slice(index, size + index));
			    index += size;
			  }
			  return chunked_arr
			}
	  }
	}
</script>