<template>
  <div class="wrapper monitor-wrapper">
    <CToaster :autohide="3000">
      <template v-for="(toast,index) in toasts">
        <CToast
          :key="index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <div v-if="monitores" class="panel-lateral" :class="$store.state.monitorPanelShow ? 'opened':'closed'" :style="'height: ' + containerHeight + 'px; margin-top: ' + headerHeight + 'px;'">
      <div class="inner-panel" v-show="$store.state.monitorPanelShow">
        <CTabs variant="pills" class="custom-monitoreo-tabs">
          <CTab title="Monitores" active>
            <template #title>
              <img height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 14@2x.png')"/>
              Monitores
            </template>
            <CInput
              v-show="monitoresAll.length > 0"
              class="mx-2 mt-2 mb-0"
              placeholder="Buscar por usuario"
              @input="searchMonitores"
            >
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>
            <div v-if="monitores.length > 0" class="monitor-container" v-for="(monitor, index) in monitores">
              <div class="mb-2 name-user-monitoreo" v-show="index === 0 || (index > 0 && monitores[index - 1].id_usuario !== monitor.id_usuario)">
                <div class="icon-monitoreo-user"><font-awesome-icon icon="user"/></div> {{monitor.nombre}}
              </div>
              <div class="row px-1">
                <div class="col-sm-12 d-flex justify-content-start align-items-center" style="color: #DB131A">
                  <div style="position: relative;">
                    <img style="margin-right: 20px;" class="primary-color-image-overlay" height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 14@2x.png')"/>
                    <div class="active-badge-indicator-monitor"></div>
                  </div>
                  <div>
                    <p class="mb-0">Nombre del Dispositivo</p>
                    <p class="mb-0">{{monitor.deviceData ? monitor.deviceData.device_name : 'Sin Datos'}}</p>
                  </div>
                </div>
                <div class="col-sm-12 d-flex justify-content-center align-items-center" style="color: grey">
                  <div class="mt-3 buttons-container-monitoreo" v-if="monitor.userActivo">
                    <CButton
                      color="success"
                      size="sm"
                      class="mr-2 my-1"
                      style="width: 30px; height: 30px;background-color: #42AB0F;"
                      @click="resetMonitor(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="sync" />
                    </CButton>
                    <CButton
                      color="success"
                      size="sm"
                      class="mx-2 my-1"
                      style="width: 30px; height: 30px;background-color: #095FC1;"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                    <CButton
                      color="danger"
                      size="sm"
                      class="mx-2 my-1"
                      style="width: 30px; height: 30px;background-color: #DB131A;"
                      @click="toogleAlarm(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="volume-up" />
                    </CButton>
                    <CButton
                      :disabled="monitor.remoteReady"
                      color="primary"
                      size="sm"
                      class="ml-2 my-1"
                      style="flex: 1;"
                      @click="initMonitoreo(monitor)"
                    >
                      <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/> Monitorear
                    </CButton>
                  </div>
                  <div v-else>
                    <CButton
                      color="secondary"
                      size="sm"
                      class="my-1"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="monitores.length <= 0" class="text-center my-5">
              No hay monitores activos
            </div>
          </CTab>
          <CTab title="Personales">
            <template #title>
              <img height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 13@2x.png')"/>
              Personales
            </template>
            <CInput
              v-show="personalesAll.length > 0"
              class="mx-2 mt-2 mb-0"
              placeholder="Buscar por usuario"
              @input="searchPersonales"
            >
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>
            <div v-if="personales.length > 0" class="monitor-container px-3" v-for="(monitor, index) in personales">
              <div class="mb-2 name-user-monitoreo" v-show="index === 0 || (index > 0 && personales[index - 1].id_usuario !== monitor.id_usuario)">
                <div class="icon-monitoreo-user"><font-awesome-icon icon="user"/></div> {{monitor.nombre}}
              </div>
              <div class="row px-1">
                <div class="col-sm-12 d-flex justify-content-start align-items-center" style="color: #DB131A">
                  <div style="position: relative;">
                    <img style="margin-right: 20px;" class="primary-color-image-overlay" height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 13@2x.png')"/>
                    <div class="active-badge-indicator-monitor"></div>
                  </div>
                  <div>
                    <p class="mb-0">Nombre del Dispositivo</p>
                    <p class="mb-0">{{monitor.deviceData ? monitor.deviceData.device_name : 'Sin Datos'}}</p>
                  </div>
                </div>
                <div class="col-sm-12 d-flex justify-content-center align-items-center" style="color: grey">
                  <div class="mt-3 buttons-container-monitoreo" v-if="monitor.userActivo">
                    <CButton
                      color="success"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #42AB0F;"
                      @click="resetMonitor(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="sync" />
                    </CButton>
                    <CButton
                      color="success"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #095FC1;"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                    <CButton
                      color="danger"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #DB131A;"
                      @click="toogleAlarm(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="volume-up" />
                    </CButton>
                    <CButton
                      color="success"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #8a93a2;"
                      @click="setMapPosition(monitor)"
                    >
                      <font-awesome-icon icon="search-location" />
                    </CButton>
                    <CButton
                      :disabled="monitor.remoteReady"
                      color="primary"
                      size="sm"
                      class="ml-2 my-1"
                      style="flex: 1;"
                      @click="initMonitoreo(monitor)"
                    >
                      <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/> Monitorear
                    </CButton>
                  </div>
                  <div v-else>
                    <CButton
                      color="secondary"
                      size="sm"
                      class="my-1"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="personales.length <= 0" class="text-center my-5">
              No hay dispositivos activos o en espera
            </div>
          </CTab>
        </CTabs>
      </div>
      <button type="button" class="btn btn-pill btn-primary custom-btn"
        @click="commitPanelShow()">
        <font-awesome-icon :icon="$store.state.monitorPanelShow ? 'chevron-right':'chevron-left'"/>
      </button>
    </div>
    <!-- <MonitoreoGrid /> -->
  </div>

</template>

<script>
import io from 'socket.io-client'
import * as firebase from 'firebase/app'
import ReportECG from './ReportECG'
import 'firebase/database'
import {getDetails, startMonitoreo} from '../../api/user'
import {startAtencion, endAtencion} from '../../api/atenciones'
import {uploadFile} from '../../api/files'
import VueDraggableResizable from 'vue-draggable-resizable'

import MonitoreoGrid from './MonitoreoGrid'
import NewNoteForm from './Components/NewNoteForm'

import RecordRTC, {invokeSaveAsDialog} from 'recordrtc'

export default {
  name: 'Monitoreo',
  components: {ReportECG, VueDraggableResizable, MonitoreoGrid, NewNoteForm},
  props: ['showCloseAlert'],
  data () {
    return {
      showUserInfo: false,
      showNotasForm: false,
      collapse: false,
      headerHeight: 56,
      containerHeight: 700,
      showModal: false,
      showPanel: true,
      monitoreo: {
        socketURL: this.$store.state.userData.info.cuadrante.socket_url,
        localStream: null,
        pcPeers: [],
        monitoreoActivo: false,
        socket: null,
        roomIDActivo: null,
        socketIdActivo: null,
      },
      monitoreoActivo: null,
      monitores: [],
      personales: [],
      monitoresAll: [],
      personalesAll: [],
      testLocal: null,
      monitorActivoUser: null,
      loadingMonitoreo: false,
      toasts: [],
      userVideo: false,
      userLuz: false,
      userAlarm: false,
      fullMonitor: false,
      admin: this.$store.state.userData,
      cuadrante: this.$store.state.userData.info.cuadrante_id,
      alerta: null,
      closeAlertCheck: false,
      remoteCloseMonitoreo: false,
      restartingMonitoreo: false,
      medicion: {},
      medicionChecking: false,
      medicionSmartband: null,
      showReportData: true,
      reportData: null,
      showPIP: false,
      remoteStream: null,
      atencionID: null,
      recorder: null,
      recording: false,
      startRecord: false
    }
  },
  beforeDestroy(){
    this.$events.remove('startMonitoreo')
    this.$events.remove('startMonitoreoFromUserList')
  },
  watch:{
    $route (to, from){
      if(to !== 'Dashboard'){
        this.$store.commit('setPanelShow', false)
      }
    }
  },
  async mounted(){
    const self = this
    if(this.$router.currentRoute.name !== 'Dashboard'){
        this.$store.commit('setPanelShow', false)
    }
    this.$events.listen('startMonitoreoFromUserList', eventData => {
      this.initMonitoreo(eventData)
    })
    this.$events.listen('startMonitoreo', eventData => {
      self.alerta = null
      self.closeAlertCheck = false
      if(eventData.alerta){
        self.alerta = eventData.alerta
        self.closeAlertCheck = true
      }
      if(eventData.showVideo){
        this.userVideo = true
        firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + eventData.deviceID + '/showVideo').set(true)
      }
      if(eventData.startRecord){ this.startRecord = true }
      this.initMonitoreo(eventData, eventData.showPIP)
    })
    this.headerHeight = document.getElementsByTagName('header')[0].clientHeight
    this.containerHeight = document.getElementsByTagName('main')[0].clientHeight
    this.monitoreo.socket = io(this.monitoreo.socketURL,
        {
          transports: ['websocket'],
          autoConnect: false,
          reconnect: true,
          forceNew: true
        })
    this.monitoreo.socket.on('connect', function(data) {
      self.setMedia(self.monitoreo.monitoreoActivo.deviceID, self.monitoreo.monitoreoActivo)
    });
    this.monitoreo.socket.on('exchange', function(data){
        if (data.from == self.monitoreo.socketIdActivo)
            self.exchange(data);
    });
    this.monitoreo.socket.on('leave', function(socketId){
        if (socketId == self.monitoreo.socketIdActivo)
            self.leave();
    });
    this.usersActivo()
  },
  methods: {
    async startRecording(){
      if(this.remoteStream){
        this.recorder = new RecordRTC(this.remoteStream, { type: 'video', mimeType: 'video/mp4' })
        this.recorder.startRecording()
        this.recording = true
      }
    },
    stopRecording(){
      if(this.recorder){
        this.recorder.stopRecording(async () => {
          this.recording = false
          const blob = this.recorder.getBlob()
          const date = + new Date()

          let formData = new FormData()
          formData.append('id_usuario', this.monitoreoActivo.id_usuario)
          formData.append('id_admin', this.$store.state.userData.id)
          formData.append('video', blob)
          formData.append('atencion_id', this.atencionID)
          formData.append('device_id', this.monitoreoActivo.deviceData.id)
          uploadFile(formData, 'Grabación Videollamada')
        })
      }
    },
    async setStartAtencion(){
      const atencion = await startAtencion({user_id: this.monitoreoActivo.id_usuario})
      if(atencion.code === 200){
        this.atencionID = atencion.atencion.id
      }
    },
    startPIP(){
      this.showPIP = true
    },
    toggleUserInfo(){
      this.showUserInfo = !this.showUserInfo
    },
    startMedicion(userID){
      const self = this
      this.medicion = {}
      this.medicionChecking = true
      firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).set({
        requestMedicion: true
      })
    },
    stopMedicion(userID, close = false){
      const self = this
      this.medicion = {}
      this.medicionChecking = false
      firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).set({
        stopMedicion: close
      })
    },
    setMapPosition(monitor){
      const center = {
        center: {
          lat: monitor.latitud,
          lng: monitor.longitud
        },
        zoom: 18
      }
      this.$store.commit('setMapCenter', center)
    },
    commitPanelShow(){
      this.$store.commit('setPanelShow', !this.$store.state.monitorPanelShow)
    },
    searchPersonales(search){
      this.personales = this.personalesAll.filter(function(item){
        return item.nombre.toLowerCase().includes(search) || item.nombre.includes(search)
      })
    },
    searchMonitores(search){
      this.monitores = this.monitoresAll.filter(function(item){
        return item.nombre.toLowerCase().includes(search) || item.nombre.includes(search)
      })
    },
    checkCloseAlert(close){
      this.closeAlertCheck = close
    },
    toggleFullMonitor(){
      this.fullMonitor = !this.fullMonitor
    },
    setMessage(type = 0, user, message){
      const found = this.monitores.findIndex(function(item,index){
        return item.id_usuario === user
      })
      if(found !== -1){
        const monitor = Object.assign( this.monitores[found], {message: message} )
        this.$set(this.monitores, found, monitor)
        this.monitores.sort()
      }
    },
    message(type = 0, user){
      const found = this.monitores.findIndex(function(item,index){
        return item.id_usuario === user
      })
      if(found !== -1){
        return this.monitores[found].message
      }
      return "Prueba mensaje"
    },
    checkOnline(idUser){
      const self = this
      self.setMessage(0, idUser, "llamada")
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + idUser + '/remoteChecking').set(false)
      setTimeout(function() {
        firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + idUser + '/remoteChecking').once('value',function(data){
            if(data.val() === false){
              setTimeout(function() {
                firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + idUser).remove()
              }, 2000);
            }
        });
      }, 3000);
    },
    usersActivo(){
      const self = this
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/').on('child_added',function(data){
        let monitor = data.val()
        monitor.key = data.key
        if(monitor.deviceData && monitor.deviceData.is_monitor){
          self.monitores.push(monitor)
          self.monitores.sort((a, b) => a.id_usuario - b.id_usuario)
        }
        else{
          self.personales.push(monitor)
          self.personales.sort((a, b) => a.id_usuario - b.id_usuario)
        }
        self.monitoresAll = self.monitores
        self.personalesAll = self.personales
      })
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/').on('child_changed',function(data){
        const found = self.monitores.findIndex(function(item, index){
          return item.key === data.key
        })
        if(found !== -1){
          let monitor = data.val()
          monitor.key = data.key
          self.monitores[found] = monitor
          self.monitores.sort((a, b) => a.id_usuario - b.id_usuario)
        }
        else{
          const found = self.personales.findIndex(function(item, index){
            return item.key === data.key
          })
          if(found !== -1){
            let monitor = data.val()
            monitor.key = data.key
            self.personales[found] = monitor
            self.personales.sort((a, b) => a.id_usuario - b.id_usuario)
          }
        }
        self.monitoresAll = self.monitores
        self.personalesAll = self.personales
      })
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/').on('child_removed',function(data){
        const removed = data.val()
        self.monitores = self.monitores.filter(function(item,index){
          return item.deviceID !== removed.deviceID
        })
        self.personales = self.personales.filter(function(item,index){
          return item.deviceID !== removed.deviceID
        })
        self.monitoresAll = self.monitores
        self.personalesAll = self.personales
      })
    },
    stopMonitoreo(close = false, change = true, userID = null){
      const self = this
      return new Promise(async function(resolve, reject){
        if(userID){
          self.stopMedicion(userID, true)
        }
        if(self.closeAlertCheck){
          self.showCloseAlert(self.alerta)
        }
        if(change){
          self.userVideo = false
          self.userAlarm = false
          self.userLuz = false
          self.alerta = null
          self.closeAlertCheck = false
          self.showPIP = false
        }
        await self.leave(close, change)
        self.medicion = {}
        self.medicionChecking = false
        self.medicionSmartband = null
        if(self.atencionID && close){
          endAtencion({atencion_id: self.atencionID})
        }
        setTimeout(function() {
          resolve(true)
        }, 1000);
      })
    },
    toogleAlarm(monitor){
      const self = this
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/startSound').set(!this.userAlarm ? true:null)
      this.userAlarm = !this.userAlarm
    },
    toogleFlash(monitor){
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/light').set(!this.userLuz ? true:null)
      this.userLuz = !this.userLuz
    },
    changeCamera(monitor){
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/changeCamera').set(true)
    },
    showVideo(monitor){
      this.userVideo = !this.userVideo
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/showVideo').set(this.userVideo)
    },
    resetMonitor(monitor){
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/resetMonitoreo').set(true)
    },
    awakePhone(monitorID){
      const self = this
      return new Promise(async function(resolve,reject){
        await firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).remove()
        await startMonitoreo(monitorID) //notificación para despertar
        let auxSetTimeout = setTimeout(() => {
          firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).off('child_added');
          resolve(false);
        }, 50000);

        firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).on('child_added',async function(data){
          if(data.key == 'userActivo' && data.val() === true){
            clearTimeout(auxSetTimeout);
            firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).off('child_added');
            resolve(true);
          }
        });
      });
    },
    async restartMonitoreo(){
      await this.stopMonitoreo(false,false, this.monitoreo.monitoreoActivo.id_usuario)
      const monitoreo = this.monitoreo.monitoreoActivo
      this.loadingMonitoreo = true
      this.remoteCloseMonitoreo = false
      this.restartingMonitoreo = true
      if(this.$store.state.showVideo){
        this.userVideo = true
        firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + monitoreo.deviceID + '/showVideo').set(true)
      }
      this.remoteCloseMonitoreo = false
      const awake = await this.awakePhone(monitoreo.deviceID)
      if(awake){
        this.monitoreo.socket.connect()
      }
      else{
        this.toasts.push({
          title: 'Error',
          message: 'No pudimos levantar el monitoreo en el dispositivo seleccionado en el tiempo establecido.'
        })
        this.remoteCloseMonitoreo = true
        this.loadingMonitoreo = false
        this.restartingMonitoreo = false
      }
    },
    async initMonitoreo(monitor, showPIP = false){
      const self = this
      self.loadingMonitoreo = true
      self.showModal = true
      self.showPIP = showPIP
      self.atencionID = null
      self.monitorActivoUser = null
      self.remoteCloseMonitoreo = false
      if(this.$store.state.showVideo){
        this.userVideo = true
        firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor.deviceID + '/showVideo').set(true)
      }
      const found = this.$store.state.usersInMonitoreo.find((item) => {return item.deviceID === monitor.deviceID})
      if(found){
        firebase.database(this.$store.state.monitoreoDB).ref('SOSclick')
          .child('MonitoreoGrid/' + this.cuadrante + '/' + monitor.deviceID)
          .child('startingMonitoreo').set(true)
      }
      if(!self.monitoreo.monitoreoActivo){
        self.monitoreo.monitoreoActivo = monitor
        self.monitoreoActivo = monitor
        //await startMonitoreo(monitor.id_usuario)
        const user = await getDetails(monitor.id_usuario)
        self.monitorActivoUser = user.user
        self.monitoreo.socket.connect()
      }
    },
    changeMonitor(monitor){
      const self = this
      //this.loadingMonitoreo = true
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor.device_id).once('value',function(data){
        if(data.val()){
          self.loadingMonitoreo = true
          self.monitoreo.monitoreoActivo = data.val()
          self.changeRoom()
          self.collapse = false
        }
        else{
          self.toasts.push({
            title: 'No encontrado',
            message: 'Este dispositivo no puede ser activado en este momento.'
          })
        }
      })
    },
    setLocalVideo(remote = false){
      if(this.showPIP){
        if(remote){
          var ViewAuxPIP = document.getElementById("monitor-remote-pip")
          var selfViewLocal = document.createElement('video')
          selfViewLocal.id = "monitor-remote-pip-view"
          selfViewLocal.srcObject = this.remoteStream
          selfViewLocal.autoplay = 'autoplay'
          ViewAuxPIP.appendChild(selfViewLocal)
          if(this.startRecord && this.$store.state.autoVideoRecordOnAlert){
            this.startRecording()
            this.startRecord = false
            this.toasts.push({
              title: 'Grabación Videollamada',
              message: 'Esta videollamada está siendo grabada. Sólo se graba el video del usuario.'
            })
          }
        }
      }
      else{
        if(remote){
          var ViewAuxRemote = document.getElementById("monitor-remote");
          var selfViewPaciente = document.createElement('video');
          selfViewPaciente.id = "remote-view";
          selfViewPaciente.srcObject = this.remoteStream;
          selfViewPaciente.autoplay = 'autoplay';
          selfViewPaciente.onloadedmetadata = function(e){
              self.conectado = true;
          };          
          ViewAuxRemote.appendChild(selfViewPaciente);
          if(!this.atencionID){
            this.setStartAtencion()
          }
          if(this.startRecord && this.$store.state.autoVideoRecordOnAlert){
            this.startRecording()
            this.startRecord = false
            this.toasts.push({
              title: 'Grabación Videollamada',
              message: 'Esta videollamada está siendo grabada.'
            })
          }
        }
        else{
          var ViewAux = document.getElementById("monitor");
          var selfViewLocal = document.createElement('video');
          selfViewLocal.id = "local-view";
          selfViewLocal.srcObject = this.monitoreo.localStream;
          selfViewLocal.autoplay = 'autoplay';
          ViewAux.appendChild(selfViewLocal);
          selfViewLocal.muted = "muted"
        }
      }
    },
    goPicInPic(){
      this.showPIP = true
      this.setLocalVideo(true)
    },
    async setMedia(roomID = 1, monitor){
      const self = this
      if(!self.monitoreo.localStream || (self.monitoreo.localStream && !self.monitoreo.localStream.active) ){
        self.monitoreo.localStream = await self.getUserMedia(true,true);
        this.setLocalVideo()
      }
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).update({
        remoteActivo: true,
        remoteSocketID: self.monitoreo.socket.id
      })

      let validarConexion = new Promise(function(resolve,reject){
          let auxSetTimeout = setTimeout(() => {
            firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).off('child_added');
            resolve(false);
          },10000);

          firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID + '/userActivo').once('value',function(data){
              if(data.val() === true){
                clearTimeout(auxSetTimeout);
                firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).off('child_added');
                resolve(true);
              }
          });

          firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).on('child_added',function(data){
              if(data.key == 'userActivo' && data.val() === true){
                clearTimeout(auxSetTimeout);
                firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).off('child_added');
                resolve(true);
              }
          });
      });
  
      let aux = await validarConexion;

      if(!aux){
          self.leave();
      }

      let setValueSokectID = new Promise(function(resolve,reject){

          let auxSetTimeout = setTimeout(() => {
            firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).off('child_added');
            resolve(null);
          },10000);

          firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID + '/socketID').once('value',function(data){
              if(data.val() !== null){
                clearTimeout(auxSetTimeout);
                resolve(data.val());
              }
                  
          });

          firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).on('child_added',function(data){
              if(data.key == 'socketID' && data.val() !== null ){
                firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).off('child_added');
                clearTimeout(auxSetTimeout);
                resolve(data.val());
              }
          });
      });
      
      let socketID = await setValueSokectID;

      if(socketID === null){
          self.leave();
      }
      else{
        self.join(roomID,socketID,roomID);
        self.monitoreo.roomIDActivo = roomID;
        self.monitoreo.socketIdActivo = socketID;
      }
    },
    getUserMedia(video = true, audio = true){
      return new Promise(function(resolve, reject){
        let setTimeoutMedia = setTimeout(function(){
            reject();
        },40000)
        navigator.getUserMedia = navigator.getUserMedia || navigator.mozGetUserMedia || navigator.webkitGetUserMedia || navigator.msGetUserMedia;

        navigator.getUserMedia ({
          video: {
            width: { min: 426, ideal: 480, max: 1280 },
            height: { min: 240, ideal: 360, max: 720 },
            frameRate: { ideal: 10, max: 15 }
          },
          audio
        },
          function(localMediaStream) {
            clearTimeout(setTimeoutMedia);
            resolve(localMediaStream);
        },
          function(err) {
            alert('El navegador no ha permitido el uso de la cámara y/o micrófono. O es posible que el computador no tenga una cámara conectada. Por favor revisa la configuración de tu navegador para luego continuar con el monitoreo.')
            console.log("Ocurrió el siguiente error: " + err);
        });
      });
    },
    join(roomID,socketIDUser,idUser) {
      const self = this
      this.monitoreo.socket.emit('join', roomID, function(socketIds){
        firebase.database(self.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).update({remoteReady: true});
        for (var i in socketIds) {
          var socketID = socketIds[i];
          if(socketIDUser == socketID){
            self.createPC(socketID, true,roomID);
          }
        }
      });
    },
    leave(close = false, change = true) {
      const self = this
      return new Promise(function(resolve, rejec){
        self.stopRecording()
        self.monitoreo.pcPeers.forEach(pc => {
          if (pc){
            pc.close();
          }
        });
        let video = document.getElementById("remote-view");
        video && video.remove();
        let videoPIP = document.getElementById("monitor-remote-pip-view");
        videoPIP && videoPIP.remove();
        if(close){
          self.showModal = false
          self.monitoreo.localStream.getAudioTracks().forEach((track) => {
              track.enabled = false;
          });
          self.monitoreo.localStream.getVideoTracks().forEach((track) => {
              track.enabled = false;
          });
          self.monitoreo.localStream.getTracks().forEach(function(track) {
            track.stop();
          });
        }
        else{
          self.remoteCloseMonitoreo = true
          self.loadingMonitoreo = false
          self.restartingMonitoreo = !change
        }
        self.monitoreo.socket.disconnect()
        const intervalDisconec = setInterval(function () {
            if(self.monitoreo.socket.disconnected || !self.monitoreo.socket.connect){
                clearInterval(intervalDisconec);
                self.monitoreo.roomIDActivo = null
                self.monitoreo.socketIdActivo = null
                if(close){
                  self.monitoreo.monitoreoActivo = false
                  self.remoteStream = null
                  self.showPIP = false
                }
            }
            resolve(true)
        },1000);
      })
    },
    changeRoom() {
      this.monitoreo.pcPeers.forEach(pc => {
        if (pc){
          pc.close();
        }
      });
      let video = document.getElementById("remote-view");
      video && video.remove();
      let videoPIP = document.getElementById("monitor-remote-pip-view");
      videoPIP && videoPIP.remove();
      this.monitoreo.socket.disconnect()
      this.monitoreo.roomIDActivo = null
      this.monitoreo.socketIdActivo = null
      this.remoteStream = null
      this.monitoreo.socket.connect()
    },
    exchange(data) {
        var fromId = data.from;
        var pc;
        const self = this

        if (fromId in  self.monitoreo.pcPeers) {
            pc = self.monitoreo.pcPeers[fromId];
        }
        else {
            pc = self.createPC(fromId, false);
        }

        if (data.sdp) {
          pc.setRemoteDescription(new RTCSessionDescription(data.sdp),() => {
              pc.createAnswer(function(desc) {
                  pc.setLocalDescription(desc,function () {
                      if(pc){
                        self.monitoreo.socket.emit('exchange', {'to': fromId, 'sdp': pc.localDescription });
                      }
                  },(error) => console.log(error,'error 4'));
              },(error) => console.log(error,'error 5'));
          },(error) => console.log(error,'error 6'));
        }
        else {
          data.candidate !== null && data.candidate && pc.addIceCandidate(new RTCIceCandidate(data.candidate));
        }
    },
    createPC(socketId, isOffer, roomID) {
      const self = this
      var configuration = {"iceServers": [{"url": "stun:stun.l.google.com:19302"}]};
      var pc = new RTCPeerConnection(configuration);
      self.monitoreo.pcPeers[socketId] = pc;

      pc.onicecandidate = function (event) {
          if (event.candidate && event !== null && event.candidate !== null) {
              self.monitoreo.socket.emit('exchange', {'to': socketId, 'candidate': event.candidate });
          }
      };

      function createOffer() {
          pc.createOffer(function(desc) {
              pc.setLocalDescription(desc,function () {
                  self.monitoreo.socket.emit('exchange', {'to': socketId, 'sdp': pc.localDescription });
              },(error) => console.log(error,'error 2'));
          },(error) => console.log(error,'error 3'));
      }

      pc.onnegotiationneeded = function () {
          if (isOffer) {
              createOffer();
          }
      }

      pc.oniceconnectionstatechange = function(event) {
          if (event.target.iceConnectionState === 'connected') {
              createDataChannel();
          } else if(event.target.iceConnectionState === 'checking'){
              createOffer();
          }
      };

      pc.onsignalingstatechange = function(event) {
          if(event.target.connectionState === 'disconnected'){
            self.leave();
          }
      };

      pc.onaddstream = function (event) {
          self.remoteStream = event.stream
          self.setLocalVideo(true)
          self.loadingMonitoreo = false
          self.restartingMonitoreo = false
      };

      pc.addStream(self.monitoreo.localStream);

      function createDataChannel() {
          if (pc.textDataChannel) {
              return;
          }
          var dataChannel = pc.createDataChannel("text");
          dataChannel.onerror = function (error) {
          };
          dataChannel.onmessage = function (event) {
          };
          dataChannel.onopen = function () {
          };
          dataChannel.onclose = function () {
          };

          pc.textDataChannel = dataChannel;
      }

      return pc;
    }
  }
}
</script>