<template>
	<div class="camera-controls">
		<CameraButtonsMain
			v-if="main"
			:cameraData="cameraData"
			:localStream="localStream"
			:peerWithAudio="peerWithAudio"
			:refreshCamera="refreshCamera"
			:cameraConnected="cameraConnected"
			:startAudioConnection="startAudioConnection"
			:setAudioStatus="setAudioStatus"/>
		<CameraButtonsGrid
			v-else
			:cameraData="cameraData"
			:refreshCamera="refreshCamera"/>
	</div>
</template>

<script>
import CameraButtonsGrid from './CameraButtonsGrid'
import CameraButtonsMain from './CameraButtonsMain'

export default {
	name: 'CameraButtons',
	components: {CameraButtonsGrid,CameraButtonsMain},
	props: ['main', 'cameraData', 'localStream', 'peerWithAudio', 'refreshCamera', 'cameraConnected', 'startAudioConnection', 'setAudioStatus'],
}
</script>

<style scoped>
	.camera-controls {
		position: relative;
	}
	.camera-controls:hover > .grid-controls{
		opacity: 1;
	}
	.grid-controls {
		/* opacity: 0; */
		position: absolute;
		width: 100%;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.grid-button {
		background-color: rgba(0,0,0,0.4);
		aspect-ratio: 1;
		line-height: 1;
	}
	.ptz-controls{
		display: flex;
		gap: 4px;
	}
</style>