import {endpoints} from './endpoints'
import request from '../helpers/request'

export function newToken(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.newToken,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function sendSMS(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.sendSMS,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}