<template>
  <GmapMarker
    key="trakingM"
    ref="trakingM"
    :position="point"
    :clickable="true"
    :icon="{url: require('../../../assets/imgs/pin3.png'), scaledSize: { width: 20, height: 28 }}"
    :zIndex="1"
    @click="toggleStatus()"
  >
    <GmapInfoWindow v-if="componentStatus">
      <CContainer class="top-container" fluid>
        <CButtonClose
          style="font-size: 20px;position: absolute;top: 5px;right: 5px;font-weight: 400;z-index: 9999;"
          @click="toggleStatus()"/>
        <CRow>
          <CCol col="12" xl="12" class="d-flex flex-column align-items-stretch">
            <p class="mb-0"><b>Fecha: </b> {{ setDateFormat(point.date) }} </p>
            <p class="mb-0"><b>Velocidad: </b> {{parseInt( point.speed * 3.6 )}} Km/h</p>
          </CCol>
        </CRow>
      </CContainer>
    </GmapInfoWindow>
  </GmapMarker>
</template>

<script>
import {getDetails, sendCustomNotification} from '../../../api/user'
import {sendSMS} from '../../../api/calls'
import * as firebase from 'firebase/app'
import 'firebase/database'
import moment from 'moment'

export default {
  name: 'InfoPointTracking',
  props: ['point', 'pointInfo'],
  data(){
    return {
      componentStatus: false
    }
  },
  mounted(){
  },
  methods: {
    setDateFormat(date){
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    toggleStatus(){
      this.componentStatus = !this.componentStatus
    }
  }
}
</script>
<style>
  button.gm-ui-hover-effect{display:none!important;}
  .gm-style-iw.gm-style-iw-c{padding: 0;background: #F7F7F7;}
  .gm-style-iw.gm-style-iw-c .gm-style-iw-d{overflow: hidden!important;}
</style>