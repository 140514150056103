import {endpoints} from './endpoints'
import request from '../helpers/request'

export function sendNotificationMedicionIduam(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.sendNotificationMedicionIduam,
      method: 'post',
      data
    })
		  .then(function (response) {
		  	console.log("resp", response)
		    resolve(response)
		  })
		  .catch(function (error) {
		  	console.log("error: ", error)
		    resolve(error)
		  });
	})
}
export function getUserMediciones(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getUserMediciones,
      method: 'post',
      data
    })
		  .then(function (response) {
		  	console.log("resp", response)
		    resolve(response)
		  })
		  .catch(function (error) {
		  	console.log("error: ", error)
		    resolve(error)
		  });
	})
}