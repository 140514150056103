<template>
  <vue-draggable-resizable :resizable="false" :x="0" :y="200" class-name="reportecg-dragable" :z="9999999999" :parent="true" v-show="showReport">
    <div class="d-flex align-items-center" v-if="reportData">
      <div>
        <p class="title-report mb-0 font-weight-bold text-center">Reporte ECG</p>
        <p class="mb-0 text-center">Este reporte ha sido realizado por una inteligencia artificial y debe ser validado por personal médico calificado.</p>
        <div class="my-2">
          <p class="my-1 font-weight-bold text-center">{{reportData.result}}</p>
          <p class="text-center">{{reportData.description}}</p>
          <img :src="reportData.img" width="100%"/>
          <CButton
            color='primary'
            size="sm"
            class="m-1 w-100"
            @click="closeReporte()"
          >
            Cerrar Reporte
          </CButton>
        </div>
      </div>
    </div>
    <p class="mb-0 text-center" style="font-size: 10px;">Puedes mover este cuadro donde quieras y seguir trabajando en la plataforma</p>
  </vue-draggable-resizable>
</template>

<script>
import { mapGetters } from "vuex"
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
  name: 'ReportECGModal',
  components: {VueDraggableResizable},
  computed: {
    ...mapGetters(["reportData"])
  },
  data(){
    return {
      showReport: false,
      imgZoom: true
    }
  },
  watch:{
    reportData: function(data){
      if(data){
        this.showReport = true
      }
      else{
        this.showReport = false
      }
    }
  },
  methods: {    
    closeReporte(){
      this.showReport = false
      this.$store.commit('setReportECGData', null)
    }
  }
}
</script>
