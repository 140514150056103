<template>
	<div class="camera-controls">
		<div class="p-2 grid-controls">
			<div class="ptz-controls">
				<CButton
					class="rounded-circle grid-button"
					variant="outline"
					:disabled="!cameraConnected"
					@click="ptzMove('left')"
				>
					<font-awesome-icon
						icon="chevron-left"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
				<CButton
					class="rounded-circle grid-button"
					variant="outline"
					:disabled="!cameraConnected"
					@click="ptzMove('right')"
				>
					<font-awesome-icon
						icon="chevron-right"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
				<CButton
					class="rounded-circle grid-button"
					variant="outline"
					:disabled="!cameraConnected"
					@click="ptzMove('up')"
				>
					<font-awesome-icon
						icon="chevron-up"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
				<CButton
					class="rounded-circle grid-button"
					variant="outline"
					:disabled="!cameraConnected"
					@click="ptzMove('down')"
				>
					<font-awesome-icon
						icon="chevron-down"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
				<CButton
					style="display:flex; justify-content:center; align-items: center;"
					v-if="availableCommand('zoom_control')"
					class="rounded-circle grid-button"
					variant="outline"
					:disabled="!cameraConnected"
					@mousedown="handleTouchStart('1')"
					@mouseup="handleTouchEnd('1')"
				>
					<img style="height: 23px;width: 23px;" src="../../../assets/icons/svg/magnify-plus-outline.svg" alt="zoom in">
				</CButton>
				<CButton
					style="display:flex; justify-content:center; align-items: center;"
					v-if="availableCommand('zoom_control')"
					class="rounded-circle grid-button"
					variant="outline"
					:disabled="!cameraConnected"
					@mousedown="handleTouchStart('0')"
					@mouseup="handleTouchEnd('0')"
				>
					<img style="height: 23px;width: 23px;" src="../../../assets/icons/svg/magnify-minus-outline.svg" alt="zoom in">
				</CButton>
				<CButton
					class="rounded-circle grid-button ml-5"
					variant="outline"
					:disabled="!cameraConnected"
					@click="$store.commit('setMainCameraConfig', !$store.state.mainCameraConfig)"
				>
					<font-awesome-icon
						icon="cog"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
			</div>
			<div style="display:flex; gap: 4px;">
				<CButton
					v-c-tooltip="'Recarga'"
					class="rounded-circle grid-button"
					variant="outline"
					@click="refreshCamera()"
				>
					<font-awesome-icon
						icon="redo"
						style="color: white; font-size: 12px;"
					/>
				</CButton>
				<CButton
					v-c-tooltip="'Luz'"
					class="rounded-circle grid-button"
					v-if="availableCommand('floodlight_switch')"
					variant="outline"
					:disabled="!cameraConnected"
					@click="sendBoolCommands('floodlight_switch')">
					<font-awesome-icon icon="lightbulb" :style="{ color: commands.find(obj => obj.code === 'floodlight_switch').value  ? '#f5f242' : '#FFF' }" style="font-size: 16px" />
				</CButton>
				<CButton
					v-c-tooltip="'Sirena'"
					class="rounded-circle grid-button"
					v-if="availableCommand('siren_switch')"
					variant="outline"
					:disabled="!cameraConnected"
					@click="setSirenSwitch()">
					<font-awesome-icon icon="exclamation-triangle" :style="{ color: commands.find(obj => obj.code === 'siren_switch').value  ? '#fc0303' : '#FFF' }" style="font-size: 14px" />
				</CButton>
				<CButton
					v-c-tooltip="'Alinear Camara'"
					class="rounded-circle grid-button"
					variant="outline"
					:disabled="!cameraConnected"
					v-if="availableCommand('basic_flip')"
					@click="sendBoolCommands('basic_flip')"
				>
					<font-awesome-icon
						icon="sync"
						style="color: white; font-size: 12px;"
					/>
				</CButton>
				<CButton
					v-c-tooltip="'Audio'"
					class="rounded-circle grid-button"
					variant="outline"
					:disabled="!cameraConnected"
					@click="openAudio ? disableAudio() : enableAudio()"
				>
					<font-awesome-icon
						:icon="openAudio ? 'volume-mute' : 'volume-up'"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
				<CButton
					v-c-tooltip="'Micrófono'"
					class="rounded-circle grid-button"
					variant="outline"
					:disabled="!cameraConnected"
					@click="openMicrophone ? disableMicrophone() : enableMicrophone()"
				>
					<font-awesome-icon
						:icon="openMicrophone ? 'microphone-slash' : 'microphone'"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
				<CButton
					v-c-tooltip="'Vista general'"
					class="rounded-circle grid-button"
					variant="outline"
					@click="removeMain"
				>
					<font-awesome-icon
						icon="expand"
						style="color: white; font-size: 14px;"
					/>
				</CButton>
			</div>
		</div>
	</div>
</template>

<script>
import { commandTuyaDevice } from '@/api/cameras'

export default {
	name: 'CameraButtonsMain',
	props: ['localStream', 'refreshCamera', 'cameraConnected', 'peerWithAudio', 'startAudioConnection', 'setAudioStatus'],
	computed: {
		activeMicrophone(){
			return this.peerWithAudio && this.openMicrophone
		},
		commands(){
			return this.$store.state.mainCameraStatus
		}
	},
	data () {
		return{
			openingMicrophone: false,
			openMicrophone: false,
			openingAudio: false,
			openAudio: false,
			activatingSiren: false,
			prevVolume: null,
			isLongPress: false,
			timer: null,
		}
	},
	methods: {
		removeMain(){
			this.$store.commit('setMainCamera', null)
		},
		getStatusCamera() {
			this.$store.dispatch('getMainCameraStatus', {camera_id: this.$store.state.mainCamera})
		},
		availableCommand(command){
			return this.commands.some(obj => obj.code === command)
		},
		setSirenSwitch(){
			const value = this.commands.find(obj => obj.code === 'siren_switch').value ? 0 : 1

			if (value) {
				this.activatingSiren = true
				this.refreshCamera(false) // Fuerza reconexión sin audio
				this.prevVolume = this.commands.find(obj => obj.code === 'ipc_siren_volume').value
				commandTuyaDevice({camera_id: this.$store.state.mainCamera, command: 'ipc_siren_volume', value:0})
			}

			setTimeout(() => {
				commandTuyaDevice({camera_id: this.$store.state.mainCamera, command: 'siren_switch', value}).then(res => {
					if (!value) {
						this.getStatusCamera()
					}
				})
			}, 500)
		},
		sendBoolCommands(command) {
			const value = this.commands.find(obj => obj.code === command).value ? 0 : 1
			const data = {camera_id: this.$store.state.mainCamera, command, value}

			commandTuyaDevice(data).then(res => {
				this.getStatusCamera()
			})
		},
		ptzMove(ptzDirection){
			const ptzValues = {
				'left': "6",
				'up': "0",
				'down': "4",
				'right': "2",
			}
			commandTuyaDevice({camera_id: this.$store.state.mainCamera, command: 'ptz_control', value: ptzValues[ptzDirection]})
		},
		ptzZoom(value) {
			commandTuyaDevice({value, command: 'zoom_control', camera_id: this.$store.state.mainCamera})
		},
		handleTouchStart(value) {
			this.isLongPress = false;
			this.timer = setTimeout(() => {
				this.isLongPress = true;
				if (this.isLongPress && this.timer) {
					commandTuyaDevice({isExtreme: '1', value, command: 'zoom_control', camera_id: this.$store.state.mainCamera})
					this.isLongPress = true;
					clearTimeout(this.timer);
			}
		}, 2000);
    },
	handleTouchEnd(value) {
		if (!this.isLongPress) {
			console.log(value);
			this.ptzZoom(value)
		} else {
			this.isLongPress = false;
		}
		clearTimeout(this.timer);
			this.isLongPress = false;
    },
		disableMicrophone() {
			if (this.localStream) {
				const tracks = this.localStream.getAudioTracks();
				tracks.forEach(track => {
					track.enabled = false
				});
				this.openMicrophone = false
			}
		},
		enableMicrophone() {
			if(this.peerWithAudio){
				if (this.localStream) {
					const tracks = this.localStream.getAudioTracks();
					tracks.forEach(track => {
						track.enabled = true
					});
					this.openMicrophone = true
				}
			}
			else{
				this.openingMicrophone = true
				commandTuyaDevice({camera_id: this.$store.state.mainCamera, command: 'siren_switch', value: 0})
				this.startAudioConnection()
			}
		},
		disableAudio() {
			if (this.localStream) {
				this.openAudio = false
			}
		},
		enableAudio() {
			if(this.peerWithAudio){
				if (this.localStream) {
					this.openAudio = true
				}
			}
			else{
				this.openingAudio = true
				commandTuyaDevice({camera_id: this.$store.state.mainCamera, command: 'siren_switch', value: 0})
				this.startAudioConnection()
			}
		},
		async activateSiren() {
			commandTuyaDevice({camera_id: this.$store.state.mainCamera, command: 'ipc_siren_volume', value: this.prevVolume ? this.prevVolume:100})
			await commandTuyaDevice({camera_id: this.$store.state.mainCamera, command: 'siren_switch', value: 0})
			setTimeout(async () => {
				await commandTuyaDevice({camera_id: this.$store.state.mainCamera, command: 'siren_switch', value: 1})
				this.getStatusCamera()
			}, 800)
			this.activatingSiren = false
		}
	},
	watch: {
		cameraConnected(value){
			if(value){
				if(this.activatingSiren){
					this.activateSiren()
				}
				this.openMicrophone = (this.openMicrophone || this.openingMicrophone) && this.peerWithAudio
				this.openAudio = (this.openAudio || this.openingAudio) && this.peerWithAudio

				if(!this.openMicrophone){
					this.disableMicrophone()
				}
				if(!this.openAudio){
					this.disableAudio()
				}
				
				this.openingMicrophone = false
				this.openingAudio = false

				this.setAudioStatus(this.openAudio)
			}
		},
		openAudio(value){
			this.setAudioStatus(value)
		},
	},
	mounted () {
		this.getStatusCamera();
	},
}
</script>

<style scoped>
	.camera-controls {
		position: relative;
	}
	.camera-controls:hover > .grid-controls{
		opacity: 1;
	}
	.grid-controls {
		/* opacity: 0; */
		width: 100%;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.grid-button {
		background-color: rgba(0,0,0,0.4);
		aspect-ratio: 1;
		line-height: 1;
		width: 40px;
		height: 40px;
	}
	.ptz-controls{
		display: flex;
		gap: 4px;
	}
</style>