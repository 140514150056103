import io from 'socket.io-client'

class MultipleVideoCallSocketConnection {
	constructor(){
		this.LOG_TAG = 'MultipleVideoCallSocketConnection'
		
		this.sockets = []
		this.rooms = []
	}

	initializeSocket(socketUrl, socketCallback){
		const socket = this.getSocketByUrl(socketUrl)
		if(socket){
			return socket
		}
		const newSocket = io(socketUrl,
			{
				transports: ['websocket'],
				autoConnect: false,
				reconnect: true,
				forceNew: true
			})
		this.setSocketListeners(newSocket, socketUrl)
		this.sockets.push({url: socketUrl, socket: newSocket, status: 1})
		return newSocket
	}

	getSocketByUrl(socketUrl){
		const socketObject = this.sockets.find((socket) => {
			return socket.url === socketUrl
		})
		return socketObject
	}

	connectToSocket(socketUrl){
		const socket = this.getSocketByUrl(socketUrl)
		if(socket && !socket.connected){
			socket.socket.connect()
		}
		return socket.socket
	}

	setSocketListeners(newSocket, socketUrl){
		newSocket.on('message', (data) => {
			console.log('socketUrl: ' + socketUrl + ' | ' + 'Socket Message: ', data)
		})
	}

	joinRoom(socketUrl, roomName, userName, userID, isAdmin) {
		const socket = this.connectToSocket(socketUrl)
		if(this.rooms.indexOf(roomName) === -1){
			console.log('JOINEO')
			socket.emit('join', {roomName, userName, userID, isAdmin}, response => {
				console.log("Response: ", response)
				this.rooms.indexOf(roomName) === -1 && this.rooms.push(roomName)
			})
		}
		// console.log('socket:' , socket)
		// if(socket.rooms.has(roomName) == 0){
		// 	console.log('SI')
		// 	socket.emit('join', {roomName, userName, userID, isAdmin}, response => {
		// 		console.log("Response: ", response)
		// 	})
		// }
		// else{
		// 	console.log('NO')
		// }
	}
}

const MultipleVideoCallSocketConnectionSingleton = new MultipleVideoCallSocketConnection()
export default MultipleVideoCallSocketConnectionSingleton