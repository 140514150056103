import {endpoints} from './endpoints'
import request from '../helpers/request'

export function getUsers(data){
	console.log(data);
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.users,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function getAdminUsers(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.adminUsers,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function getSuperAdminUsers(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.superAdminUsers,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function deleteUser(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.userDelete,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function updateUser(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.updateUser,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function updateAdminUser(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.updateAdminUser,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function searchUsers(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.searchUsers,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function sendNotification(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.sendInactiveDeviceNotification,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function deleteDevice(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteDispositivo,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function sendDailyCheckNotification(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.sendDailyCheckNotification,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function excelUsers (data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.excel_users,
			method: 'get',
			params: data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getUsersGeo(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getUsersGeo,
      method: 'get',
	  params: data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function searchUserBySearch(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.searchUserBySearch,
      method: 'get',
      params: data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function AlertTest(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.AlertTest,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}