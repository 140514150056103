<template>
  <div class="container-fluid p-0" :style="'height: ' + containerHeight + 'px'">
    <CRow style="padding-top: 20px; padding-bottom: 20px">
		<CCol cols="2" xl="2">
			<CButton @click="$router.push('/dashboard')" variant="outline" color="dark" class="mt-3">Volver</CButton>
		</CCol>
		<CCol col="8" xl="8">
			<h4 class="text-center mb-0">Cámaras Activas</h4>
			<h6 class="text-center text-muted">Se muestran las cámaras que han sido compartidas con el Panel SOSclick.</h6>
		</CCol>
		<CCol col="2" xl="2" class="text-right">
				<CButtonGroup v-show="cameras.length" class="mt-3 mx-auto">
					<CButton class="mx-0" color="dark" variant="outline"
						@click="prev">
						<font-awesome-icon icon="chevron-left" style="color: #424242;font-size: 14px" />
					</CButton>
					<CButton :disabled="disableNext" class="mx-0" color="dark" variant="outline"
						@click="next">
						<font-awesome-icon icon="chevron-right" style="color: #424242;font-size: 14px" />
					</CButton>
				</CButtonGroup>
			</CCol>
    </CRow>
		<div :style="'overflow-y: scroll;height: ' + (containerHeight - 100) + 'px'">
			<div class="full-camera-container">
				<div class="main-camera-container" v-show="$store.state.mainCamera" ref="mainCameraContainer">
				</div>
				<div v-if="cameras.length" class="camera-grid-container"
					:class="$store.state.mainCamera ? 'column':''"
					:style="$store.state.mainCamera ? 'height: ' + (containerHeight - 100) + 'px':''">

					<div class="single-camera-container" v-for="(camera, index) in cameras" :key="index"
						v-show="camera.deviceId != $store.state.mainCamera"
						:ref="camera.deviceId + '_container'">
						<CameraView
							:ref="camera.deviceId"
							:cameraData="camera"
							:showCameraViewComponent="$store.state.grillaMonitoreo"
							:socket="socket"
						/>
					</div>

				</div>
				<p style="margin: 0 auto" v-else>Cargando Cámaras...</p>
			</div>
		</div>
  </div>
</template>

<script>
import CameraView from "./CameraView";
import io from 'socket.io-client';
import { cameraList } from '../../../api/cameras'
import Pagination from '../../../helpers/Pagination.vue'

export default {
  name: "TuyaCamerasGrid",
  components: {
    CameraView,
    Pagination
  },
	watch: {
    '$store.state.tuyaCameras': function(value) {
      this.cameras = [...value]
			if(!this.socket){
				this.socket = io(this.socketUrl, {
					transports: ["websocket"],
					autoConnect: true,
					reconnect: true,
					forceNew: true,
				});
			}
    },
	},
  data() {
    return {
      headerHeight: 56,
      containerHeight: 700,
      requestedVideoInfo: false,
      cameras: [],
      socket: null,
      socketUrl: 'https://devapis.anubys.cl:3000',
      loading: false,
      mainVideo: null,
			previousMainCamera: null,
			storeSubscription: null,
      pagination: {
        page: 1,
        limit: 15,
        total: null,
      },
			disableNext: true,
      changeRoute: true
    };
  },
  mounted() {
    this.containerHeight = window.innerHeight - document.getElementsByTagName('header')[0].clientHeight

    this.getCameraList();
		this.storeSubscription = this.$store.subscribe((mutation, state) => {
			if(mutation.type == 'setMainCamera'){
				this.setMainCamera(mutation.payload, this.previousMainCamera)
				this.previousMainCamera = mutation.payload
			}
		})
  },
  beforeRouteLeave(to, from, next) {
	this.$store.commit('setMainCamera', null)
    this.socket?.disconnect();
		this.storeSubscription && this.storeSubscription()
    next();
  },
  methods: {
    setMainCamera(camera_id, prev_camera_id){
			if(prev_camera_id){
				this.toGridCamera(prev_camera_id)
			}
			if(camera_id){
				const toMain = this.$refs[camera_id][0].$el
				const mainCameraContainer = this.$refs.mainCameraContainer
				mainCameraContainer.appendChild(toMain);
			}
		},
		toGridCamera(camera_id){
			const toGrid = this.$refs[camera_id][0].$el
			const gridCameraContainer = this.$refs[camera_id + '_container'][0]
			gridCameraContainer.appendChild(toGrid)
		},
    next() {
      this.pagination.page += 1
      this.getCameraList()
    },
    prev() {
      this.pagination.page -= 1
      this.getCameraList()
    },
    async getCameraList() {
      this.cameras = []
      const data = {limit: this.pagination.limit, page:this.pagination.page, keep_ids: []}
      const camaras = await cameraList(data)
      
      if (camaras.connections.length) {
        this.$store.commit('setTuyaCameras', camaras.connections)
        this.disableNext = false
      } else {
        this.disableNext = true
      }
    }
  }
}
</script>
<style scoped>

  .grid-default {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    height: 100%;
  }

  @media (min-width: 1700px) {
    .grid-default {
    height: 70vh;
    }
  }

	.full-camera-container{
/*     max-width: 1200px;
    margin: 0 auto; */
		display: flex;
		flex: 1;
	}
/*   @media screen and (min-width: 2000px) {
    .full-camera-container {
        max-width: 2000px;
    }
} */

	.main-camera-container{
		flex: 1;
		margin: 2px;
	}
	.camera-grid-container{
		display: flex;
		flex-wrap: wrap;
		flex: 1;
	}
	.camera-grid-container.column{
		flex-direction: column;
		flex-wrap: inherit;
		width: 20%;
		max-width: 300px;
		overflow-y: scroll;
	}
	.single-camera-container{
		flex: 0 0 calc(20% - 4px);
		margin: 2px;
		aspect-ratio: 16/9;
	}

</style>