<template>
  <CContainer class="upload-file-container" fluid v-show="$store.state.uploadsInProgress.length > 0">
    <CRow :gutters="false">
    	<h6 class="mb-0">Subiendo archivos</h6>
      <p style="font-size: 10px;">Por favor no cierres o actualices el panel mientras se suben los archivos</p>
      <CCol col="12" xl="12">
      	<div v-for="(upload, index) in $store.state.uploadsInProgress" :key="index">
      		<p class="mb-0" style="font-size: 10px;">{{index + 1}}.- {{upload.type}}</p>
			    <CProgress
			      :value="upload.progress"
			      color="success"
			      animated
			      show-value
			      show-percentage
			    />
			  </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>

export default {
  name: 'UploadFile'
}
</script>