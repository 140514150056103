<template>
	<div class="videocalllist-container" :class="expanded ? 'expanded':null">
		<div class="remotes-container" v-show="expanded">
			<div v-for="(remote, index) in remotes" class="videocall-self-container">
				<video autoplay :srcObject.prop="remote.stream" class="video-elements w-100"></video>
				<div class="user-name-video" >{{remote.userName}}</div>
		    <CButton class="button-list" color="dark" size="sm">
					<font-awesome-icon icon="volume-mute"/>
		    </CButton>
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-between">
	    <CButton @click="expanded = !expanded" color="light" size="sm">
				<font-awesome-icon :icon="expanded ? 'chevron-up':'chevron-down'"/>
	    </CButton>
	    <small>Otros usuarios en sala: {{remotes.length}}</small>
	    <CButton color="dark" size="sm">
				<font-awesome-icon icon="volume-mute"/>
	    </CButton>
	  </div>
	</div>
</template>

<script>

	export default {
	  name: 'VideoCallList',
	  props: ['remotes'],
	  data(){
	    return {
	    	expanded: false
	    }
	  },
	  mounted(){
	  	console.log(this.remotes)
	  },
	  methods: {
	  }
	}
</script>