import {endpoints} from './endpoints'
import request from '../helpers/request'
import store from '../helpers/store.js'

export function uploadFile(formData, type){
	return new Promise((resolve, reject) => {
		const uploadID = + new Date()		
		newUpload(uploadID, type)
    request({
      url: endpoints.uploadVideoRecord,
      method: 'post',
      data: formData,
      timeout: 600000,
      onUploadProgress: function (progress) {
      	const progressValue = parseInt ( progress.loaded * 100 / progress.total )
		    updateProgress(progressValue, uploadID)
		  },
    })
		  .then(async function (response) {
		    resolve(response)
		    setTimeout(function() {
		    	removeFromUploads(uploadID)
		    }, 3000);
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

function newUpload(uploadID, type){
	let uploads = [...store.state.uploadsInProgress]
	const uploadInfo = {
		uploadID,
		type,
		progress: 0
	}
	uploads.push(uploadInfo)
  store.commit('setUploadsInProgress', uploads)
}

function updateProgress(progress, uploadID){
	let uploads = [...store.state.uploadsInProgress]
  const foundIndex = uploads.findIndex(function(item){ return item.uploadID === uploadID })
  if(foundIndex !== -1){
  	uploads[foundIndex].progress = progress
  }
  store.commit('setUploadsInProgress', uploads)
}

function removeFromUploads(uploadID){
	let uploads = [...store.state.uploadsInProgress]
  const foundIndex = uploads.findIndex(function(item){ return item.uploadID === uploadID })
  if(foundIndex !== -1){ uploads.splice(foundIndex, 1) }
  store.commit('setUploadsInProgress', uploads)	
}