<template>
	<div class="sosclick-app-container m-0 p-0" v-if="ready || inLogin">
  	<router-view></router-view>
  	<DatabaseListeners v-if="!inLogin" :showAlert="showAlert" :showCloseAlert="(alert) => prepareShowEndAlert(alert)" />
  	<ModalEndAlert v-if="!inLogin" :showModal="showEndAlert" :alertData="alertData" :closeModal="(show) => closeEndAlert(show)" />
    <!-- <Monitoreo v-if="showMonitoreo" :showCloseAlert="(alert) => prepareShowEndAlert(alert)"/> -->
<!--     <TuyaCamerasGrid v-if="showMonitoreo" /> -->
    <LateralBarUsers v-if="!inLogin && showMonitoreo" />
    <VideoCallRoom  v-if="!inLogin && $store.state.videoCallData.status" />
    <ModalSearchUsers v-if="$store.state.showUserModalSearch" :showModal="$store.state.showUserModalSearch" :closeModal="() => closeSearchUserModal()"/>
    <Calls v-if="!inLogin" />
    <SoloTracking v-if="!inLogin" />
    <ReportECG v-if="!inLogin" />
    <UploadFile v-if="!inLogin" />
  </div>
  <div class="sosclick-app-container m-0 p-0 d-flex flex-column align-items-center justify-content-center" style="height: 100vh" v-else>
    <img src="./assets/imgs/logo_sosclick.png" width="200">
    <div class="d-flex align-items-center justify-content-center">
      <CSpinner
        color="primary"
        style="width:20px;height:20px;"
      />
      <h5 class="m-0 ml-2">Cargando datos de cuadrante</h5>
    </div>
  </div>
</template>

<script>
import DatabaseListeners from './views/notifications/DatabaseListeners'
import ModalEndAlert from './views/notifications/ModalEndAlert'
import Monitoreo from './views/widgets/Monitoreo'
import TuyaCamerasGrid from './views/widgets/TuyaCamerasView/TuyaCamerasGrid'
import Calls from './views/widgets/Calls'
import SoloTracking from './views/widgets/Tracking/SoloTracking'
import ModalSearchUsers from './views/widgets/modalSearchUsers'
import ReportECG from './views/widgets/ReportECG'
import UploadFile from './views/widgets/Components/UploadFile'
import LateralBarUsers from './views/widgets/LateralBarUsers'
import VideoCallRoom from './views/videocall/VideoCallRoom'


import {getUser, getFBAuthToken} from './api/user.js'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export default {
  name: 'App',
  components: {DatabaseListeners, ModalEndAlert, Monitoreo, Calls, ModalSearchUsers, ReportECG, SoloTracking, UploadFile, LateralBarUsers, VideoCallRoom, TuyaCamerasGrid},
  data () {
    return {
      showAlert: null,
      showEndAlert: false,
      alertData: null,
      showMonitoreo: false,
      showModalSearchUsers: false,
      ready: false,
      inLogin: true
    }
  },
  mounted(){
    this.checkMonitoreoHeight()
  },
  watch:{
    $route (to, from){
      if(to.name !== "Login"){
        this.inLogin = false
        if(!this.$store.state.userData || from.name == "Login"){ this.commitUser() }
      }
      else{
        this.inLogin = true
      }
    }
  },
  methods: {
    async commitUser(){
      const self = this
      await getUser()
      this.ready = await this.initializeFirebaseDBS()
    },
    async loginInFB(){
      return new Promise(async (resolve, reject) => {
        const fbToken = await getFBAuthToken()
        const token = fbToken.customToken
        if (token) {
          Promise.all([this.checkLoginPrimaryDB(token), this.checkLoginMonitoreoGrid(token), this.checkLoginTrackingBD(token)])
            .then(values => {
              console.log("USER: ", values)
              resolve(true)
            })
        }
      })
    },
    checkLoginPrimaryDB(token){
      return new Promise(async (resolve, reject) => {
        let loggedPrimaryDB = this.$store.state.primaryDB.auth()
          .onAuthStateChanged(async (user) => {
            if(!user){
              await this.$store.state.primaryDB.auth().signInWithCustomToken(token)
            }
            loggedPrimaryDB()
            resolve(user)
          })
      })
    },
    checkLoginMonitoreoGrid(token){
      return new Promise(async (resolve, reject) => {
        let loggedMonitoreoGrid = this.$store.state.monitoreoDB.auth()
          .onAuthStateChanged(async (user) => {
            if(!user){
              await this.$store.state.monitoreoDB.auth().signInWithCustomToken(token)
            }
            loggedMonitoreoGrid()
            resolve(user)
          })
      })
    },
    checkLoginTrackingBD(token){
      return new Promise(async (resolve, reject) => {
        let loggedTrackingBD = this.$store.state.trackingDB.auth()
          .onAuthStateChanged(async (user) => {
            if(!user){
              await this.$store.state.trackingDB.auth().signInWithCustomToken(token)
            }
            loggedTrackingBD()
            resolve(user)
          })
      })
    },
    initializeFirebaseDBS(){
      return new Promise(async (resolve, reject) => {
        const firebaseConfigPrimaryDB = {
          apiKey: "AIzaSyAG7ToUumc5o0RLc_QEh2FPZLv1Iu_HpUc",
          authDomain: "sosclick-1d067.firebaseapp.com",
          databaseURL: this.$store.state.userData.info.cuadrante.primary_fdb_url,
          projectId: "sosclick-1d067",
          storageBucket: "sosclick-1d067.appspot.com",
          messagingSenderId: "614190041654",
          appId: "1:614190041654:web:472e522e02a23dc8986697"
        };
        const firebaseConfigMonitoreo = {
          apiKey: "AIzaSyAG7ToUumc5o0RLc_QEh2FPZLv1Iu_HpUc",
          authDomain: "sosclick-1d067.firebaseapp.com",
          databaseURL: this.$store.state.userData.info.cuadrante.monitoreo_fdb_url,
          projectId: "sosclick-1d067",
          storageBucket: "sosclick-1d067.appspot.com",
          messagingSenderId: "614190041654",
          appId: "1:614190041654:web:472e522e02a23dc8986697"
        };
        const firebaseConfigTracking = {
          apiKey: "AIzaSyAG7ToUumc5o0RLc_QEh2FPZLv1Iu_HpUc",
          authDomain: "sosclick-1d067.firebaseapp.com",
          databaseURL: this.$store.state.userData.info.cuadrante.tracking_fdb_url,
          projectId: "sosclick-1d067",
          storageBucket: "sosclick-1d067.appspot.com",
          messagingSenderId: "614190041654",
          appId: "1:614190041654:web:472e522e02a23dc8986697"
        };
        const primaryDB = await firebase.initializeApp(firebaseConfigPrimaryDB, 'primaryDB')
        const monitoreoDB = await firebase.initializeApp(firebaseConfigMonitoreo, 'monitoreoGrid')
        const trackingDB = await firebase.initializeApp(firebaseConfigTracking, 'trackingDB')

        this.$store.commit('setPrimaryDB', primaryDB)
        this.$store.commit('setMonitoreoDB', monitoreoDB)
        this.$store.commit('setTrackingDB', trackingDB)

        await this.loginInFB()
        resolve(true)
      })
    },
    checkMonitoreoHeight(){
      const self = this
      const height = document.getElementsByTagName('main')[0]
      if(height){
        this.showMonitoreo = true
      }
      else{
        setTimeout(function() {
          self.checkMonitoreoHeight()
        }, 500);
      }
    },
    prepareShowEndAlert(alert){
      this.alertData = alert
      this.showAlert = false
      this.showEndAlert = true
    },
    closeEndAlert(show = true){
      this.showEndAlert = false
      this.showAlert = show ? this.alertData : null
      this.alertData = null
    },
    closeSearchUserModal(){
      this.$store.commit('setShowUserModalSearch', false)
    }
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
